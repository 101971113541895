import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container, Nav, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";


export default function Home() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "75%";


  const coverImgURL =
    "url(https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)";


  async function handleSubmit(e) {
    e.preventDefault();

    
  }

  

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
        }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card className="border-0">
            <Card.Body>
              <h1 className="text-center mb-4">Riley Events API Reference</h1>
              {error && <Alert variant="danger">{error}</Alert>}
              
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
