import React, { useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Form,
  Button,
  Dropdown,
  DropdownButton,
  Row, Col, InputGroup,
} from "react-bootstrap";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";

function App() {
  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
            <Container fluid>
              <Navbar.Brand href="/">
            Riley Events API
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/docs" style={{fontSize: '15px', fontWeight: 'bold'}}>Docs</Nav.Link>
            <Nav.Link href="https://help.rileyevents.com" style={{fontSize: '15px', fontWeight: 'bold'}}>Helps</Nav.Link>
          </Nav>
        </Navbar.Collapse>
            </Container>
          </Navbar>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
    </div>
  );
}

export default App;
